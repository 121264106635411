import React from "react";
import { SideBar } from "../../layouts/sidebar";
import { TopMenu } from "../../layouts/top_menu";
import { getAllDistricts } from "../../../models/districts";
import { Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { createLabels } from "../../../models/labels";
import {
  deleteAdvertisement,
  getAdvertisement,
  getAllAdvertisements,
  updateAdPayment,
  updateAdvertisement,
} from "../../../models/advertisements";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../../constants/styles";
import { Link } from "react-router-dom";
import { getLoggedUser } from "../../../state/user";
import { getBranches } from "../../../models/branches";
import { getActiveGST } from "../../../models/gst";
import { getAllAdAgencys } from "../../../models/ad_agencies";
import { adminAuth } from "../../../models/users";
import { apiUrl } from "../../../constants/global";

const user = getLoggedUser();
const conditionalRowStyles = [
  {
    when: (row) => row.is_opened === 0,
    style: {
      backgroundColor: "#ffc3c3",
    },
  },
];
const statuses = {
  N: {
    value: "Not paid",
    color: "danger",
  },
  P: {
    value: "Paid",
    color: "success",
  },
};

export class ListAds extends React.Component {
  constructor() {
    super();
    this.state = {
      qstring: "",
      selectedID: null,
      selectedRow: {},
      due_amount: 0,
      popup: false,
      is_hide: false,
      isOpen: false,
      ads: [],
      districts: [],
      totalRows: 0,
      currentPage: 1,
      page_input: {
        page: 1,
        limit: 15,
      },
      input: {},
      update: {},
      pwd: {},
      query: "",
      is_loading: false,
      data_arr: {},
      is_saving: false,
      incr: 0,
      payment_popup: false,
      payment: {},
      edit_popup: false,
      branches: [],
      agencies: [],
      gst: {},
      net_amount: 0,
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "100px",
        },
        {
          name: "Print",
          button: true,
          cell: (row) => (
            <a
              className="btn btn-outline-info btn-sm"
              target="_new"
              href={apiUrl + "print_ad_bill?id=" + row.id + "&u=" + user.id}
            >
              <i className="fa fa-print"></i>&nbsp; Print
            </a>
          ),
        },
        {
          name: "Payment",
          button: true,
          wrap: true,
          cell: (row) => (
            <button
              className="btn btn-outline-warning btn-sm"
              type="button"
              onClick={(e) => {
                this.setState({
                  selectedID: row.id,
                  payment_popup: true,
                  selectedRow: row,
                });
              }}
            >
              <i className="fa fa-money"></i>&nbsp;Payment
            </button>
          ),
        },
        {
          name: "Category",
          cell: (row) => {
            if (user.role === "A" && row.category === "DAVP") {
              return "Magazine";
            }
            return row.category;
          },
        },
        {
          name: "Agent",
          cell: (row) => (row.agency_info ? row.agency_info.name : ""),
        },
        {
          name: "Client",
          cell: (row) => row.client,
        },
        {
          name: "Bill Number",
          cell: (row) => row.bill_number,
          wrap: true,
        },
        {
          name: "Bill Date",
          cell: (row) => row.bill_date,
          wrap: true,
        },
        {
          name: "Taxable Amount",
          cell: (row) => row.taxable_amount,
          wrap: true,
        },
        {
          name: "Net Amount",
          cell: (row) => row.net_amount,
          wrap: true,
        },
        {
          name: "Paid",
          cell: (row) => row.paid_amount,
          wrap: true,
        },
        {
          name: "Due",
          cell: (row) => row.due_amount,
          wrap: true,
        },
        {
          name: "Payment Mode",
          cell: (row) => row.payment_mode,
          wrap: true,
        },
        {
          name: "Date of payment",
          cell: (row) => row.payment_date,
          wrap: true,
        },
        {
          name: "Status",
          cell: (row) => (
            <span className={`text-${statuses[row.status].color}`}>
              {statuses[row.status].value}
            </span>
          ),
        },
        {
          name: "Edit",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-outline-dark btn-sm"
              type="button"
              onClick={(e) => {
                this.openView(row.id);
              }}
            >
              <i className="fa fa-edit"></i>&nbsp; Edit
            </button>
          ),
        },
        {
          name: "Delete",
          button: true,
          cell: (row) => (
            <button
              className="btn btn-danger btn-sm"
              onClick={(e) => {
                var ans = window.confirm("Do you want to delete.?");
                if (ans) {
                  this.removeRow(row.id);
                }
              }}
            >
              <i className="fa fa-times"></i>&nbsp; Delete
            </button>
          ),
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMultipleSelectChange =
      this.handleMultipleSelectChange.bind(this);
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.saveData = this.saveData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.updatePayment = this.updatePayment.bind(this);
    this.openView = this.openView.bind(this);
    this.passwordCheck = this.passwordCheck.bind(this);
    this.updateAd = this.updateAd.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ is_hide: true });
    }, 500);
    this.loadDistricts();
    let input = this.state.page_input;
    this.loadads(input);
    this.loadBranches();
    this.loadDistricts();
    this.loadActiveGST();
  }

  async loadBranches() {
    let q = "all=1";
    let response = await getBranches(q);
    if (response.success) {
      this.setState({ branches: response.data });
    }
  }

  async loadDistricts() {
    let q = "all=1";
    let response = await getAllDistricts(q);
    if (response.success) {
      this.setState({ districts: response.data });
    }
  }

  async loadActiveGST() {
    let response = await getActiveGST();
    if (response.success) {
      this.setState({ gst: response.data });
    }
  }

  async loadAgencies(dist) {
    this.setState({ is_loading: true });
    this.setState({ agencies: {} });
    let q = new URLSearchParams({
      district: dist,
    }).toString();
    let response = await getAllAdAgencys(q);
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ agencies: response.data });
    } else {
      this.setState({ agencies: [] });
      this.setState({ is_loading: false });
      swal("Failed", "No data found !!!", "error");
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "district") {
      let { page_input } = this.state;
      page_input.district = event.target.value;
      this.loadads(page_input);
    }
    if (event.target.name === "category") {
      let { page_input } = this.state;
      page_input.category = event.target.value;
      this.loadads(page_input);
    }
  }

  handleUpdateChange(event) {
    let { update } = this.state;
    update[event.target.name] = event.target.value;
    this.setState({ update });
    if (event.target.name === "district" && event.target.value) {
      this.loadAgencies(event.target.value);
    }
    if (event.target.name === "ad_agency" && event.target.value) {
      let { agencies, update } = this.state;
      let agency = agencies.find(
        (item) => Number(item.id) === Number(event.target.value)
      );
      update.agency_gst = agency.gst;
      this.setState({ update });
    }

    if (event.target.name === "rate" && event.target.value) {
      let { update } = this.state;
      let bill_amount =
        Number(update.ad_height) *
        Number(update.ad_width) *
        Number(update.rate);
      update.bill_amount = bill_amount;
      update.taxable_amount = bill_amount;
      this.setState({ update });
      this.setState({ net_amount: bill_amount });
    }
    if (event.target.name === "discount" && event.target.value) {
      let { update } = this.state;
      let taxable_amount =
        Number(update.bill_amount) -
        (Number(update.bill_amount) * Number(event.target.value)) / 100;
      update.taxable_amount = taxable_amount;
      this.setState({ update });
      this.setState({ net_amount: taxable_amount });
    }
    if (event.target.name === "bill_amount" && event.target.value) {
      let { update } = this.state;
      update.taxable_amount = event.target.value;
      let taxable_amount = 0;
      if (update.discount > 0) {
        let taxable_amount =
          Number(event.target.value) -
          (Number(event.target.value) * Number(update.discount)) / 100;
        update.taxable_amount = taxable_amount;
      }
      this.setState({ update });
      this.setState({ net_amount: taxable_amount });
    }

    this.setNetAmount();
    // if (event.target.name === "rate" && event.target.value) {
    //   let { update } = this.state;
    //   let bill_amount =
    //     Number(update.ad_height) *
    //     Number(update.ad_width) *
    //     Number(update.rate);
    //   update.bill_amount = bill_amount;
    //   this.setState({ update });
    // }
    // if (event.target.name === "discount" && event.target.value) {
    //   let { update } = this.state;
    //   let taxable_amount =
    //     Number(update.bill_amount) -
    //     (Number(update.bill_amount) * Number(event.target.value)) / 100;
    //   update.taxable_amount = taxable_amount;
    //   this.setState({ update });
    // }
  }

  handleMultipleSelectChange(event) {
    const { name, options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    this.setState({
      update: {
        ...this.state.update,
        [name]: selectedValues,
      },
    });
  }

  handlePaymentChange(event) {
    let { payment, selectedRow } = this.state;
    payment[event.target.name] = event.target.value;
    if (event.target.name === "paid_amount" && event.target.value) {
      let due = Number(selectedRow.net_amount) - Number(event.target.value);
      this.setState({ due_amount: due });
    }
    this.setState({ payment });
  }

  handlePageChange = (page) => {
    let pinput = this.state.page_input;
    pinput.page = page;
    this.setState({ currentPage: page });
    this.setState({ page_input: pinput });
    this.loadads(pinput);
  };

  setNetAmount() {
    let { update, net_amount, gst } = this.state;
    this.setState({
      net_amount: update.taxable_amount,
    });
    let net_gst = 0;
    if (update.is_sgst) {
      net_gst += Number(gst.sgst);
    }
    if (update.is_cgst) {
      net_gst += Number(gst.cgst);
    }
    if (update.is_igst) {
      net_gst += Number(gst.igst);
    }
    net_amount =
      Number(update.taxable_amount) +
      Number((update.taxable_amount * net_gst) / 100);
    this.setState({ net_amount });
  }

  async removeRow(id) {
    let response = await deleteAdvertisement(id);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).catch((response) => {
        swal("Failed", response.message, "error");
      });
      let { input } = this.state;
      this.loadads(input);
    }
  }

  async loadads(input) {
    let { data_arr } = this.state;
    this.setState({ is_loading: true });
    data_arr = {};
    this.setState({ data_arr });
    this.setState({ ads: {} });

    if (
      this.state.input.district &&
      typeof this.state.input.district !== "undefined"
    ) {
      input.district = this.state.input.district;
    }
    if (
      this.state.input.category &&
      typeof this.state.input.category !== "undefined"
    ) {
      input.category = this.state.input.category;
    }
    let q = new URLSearchParams(input).toString();
    let response = await getAllAdvertisements(q);
    if (response.success) {
      let meta = response.meta;
      this.setState({ totalRows: meta.total });
      this.setState({ is_loading: false });
      this.setState({ ads: response.data });
    } else {
      this.setState({ ads: [] });
      this.setState({ is_loading: false });
      swal("Failed", "No data found !!!", "error");
    }
  }

  async saveData() {
    if (!this.state.input.label_date) {
      swal("Failed", "Please select a valid date.", "error");
      return;
    }
    if (!this.state.input.district) {
      swal("Failed", "Please select a district.", "error");
      return;
    }
    this.setState({ is_saving: true });
    let payload = this.state.input;
    payload.data = this.state.data_arr;
    let response = await createLabels(payload);
    if (response.success) {
      this.setState({ is_saving: false });
      this.setState({ data_arr: {} });
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          setTimeout(function () {
            window.location.reload();
          }, 50);
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      this.setState({ is_saving: false });
      swal("Failed", response.message, "error");
    }
  }

  async updatePayment() {
    let { selectedID, payment } = this.state;
    let payload = {
      id: selectedID,
      paid_amount: payment.paid_amount,
      payment_mode: payment.mode,
    };
    const response = await updateAdPayment(payload, selectedID);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          let { page_input } = this.state;
          this.setState({ payment_popup: false });
          this.loadads(page_input);
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    }
  }

  async updateAd() {
    let { selectedID, update } = this.state;
    let payload = {
      id: selectedID,
      ...update,
    };
    const response = await updateAdvertisement(payload, selectedID);
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((response) => {
          let { page_input } = this.state;
          this.setState({ payment_popup: false });
          this.loadads(page_input);
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    }
  }

  openView(id) {
    this.setState({ selectedID: id });
    if (user.role !== "A") {
      this.setState({ password_popup: true });
      return;
    }
    this.getData(id);
    this.setState({ edit_popup: true });
  }

  async getData(id) {
    this.setState({ update: {} });
    const response = await getAdvertisement(id);
    let update = response.data;
    update.is_igst = false;
    update.is_sgst = false;
    update.is_cgst = false;
    if (response.success) {
      this.loadAgencies(update.district);
      if (Number(update.igst) > 0) {
        update.is_igst = true;
      }
      if (Number(update.sgst) > 0) {
        update.is_sgst = true;
      }
      if (Number(update.cgst) > 0) {
        update.is_cgst = true;
      }
      this.setState({ update });
      this.setNetAmount();
    }
  }

  async passwordCheck() {
    let { pwd, selectedID } = this.state;
    let payload = { password: pwd.password };
    const response = await adminAuth(payload);
    if (response.success) {
      this.getData(selectedID);
      this.setState({ password_popup: false, edit_popup: true });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="hold-transition sidebar-mini">
          <div className="wrapper">
            <TopMenu title="Advertisement List" />
            <SideBar />
            <div className="content-wrapper p-2">
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title mr-auto">Filter</h3>
                          <Link
                            to="/create_ads"
                            className="btn btn-outline-warning btn-sm"
                          >
                            Add Advertisement
                          </Link>
                        </div>

                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label for="inputDate">Bill Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Enter Date"
                                  name="bill_date"
                                  value={this.state.input.bill_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label for="inputDate">Category</label>
                                <select
                                  className="form-control"
                                  name="category"
                                  value={this.state.input.category}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  <option value="Private">Private</option>
                                  <option value="PRD">PRD</option>
                                  <option value="Lottery">Lottery</option>
                                  <option value="DAVP">
                                    {user.role === "A" ? "Magazine" : "DAVP"}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label for="inputDate">District</label>
                                <select
                                  className="form-control"
                                  name="district"
                                  value={this.state.input.district}
                                  onChange={this.handleChange}
                                >
                                  <option value="">--Select--</option>
                                  {this.state.districts.length > 0 &&
                                    this.state.districts.map((item, i) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card-secondary">
                        <div className="card-header d-flex">
                          <h3 className="card-title m-auto">
                            List of ads ({this.state.ads.length})
                          </h3>
                        </div>

                        <div className="card-body table-responsive">
                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                {this.state.is_loading && (
                                  <div className="text-center p-5">
                                    <Spinner animation="grow" size="lg" />
                                  </div>
                                )}

                                {!this.state.is_loading &&
                                  this.state.ads.length > 0 && (
                                    <DataTable
                                      customStyles={tableCustomStyles}
                                      title=""
                                      fixedHeader
                                      columns={this.state.columns}
                                      data={this.state.ads}
                                      highlightOnHover
                                      pagination
                                      paginationServer
                                      paginationTotalRows={this.state.totalRows}
                                      paginationPerPage={15}
                                      paginationDefaultPage={
                                        this.state.currentPage
                                      }
                                      paginationRowsPerPageOptions={[
                                        15, 25, 50, 100,
                                      ]}
                                      paginationComponentOptions={{
                                        rowsPerPageText: "Ads per page:",
                                        rangeSeparatorText: "out of",
                                      }}
                                      onChangePage={this.handlePageChange}
                                      conditionalRowStyles={
                                        conditionalRowStyles
                                      }
                                    />
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <Modal
          size="md"
          show={this.state.payment_popup}
          onHide={() => {
            this.setState({ payment_popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="edit-scheme">
              Payment(Net Amount : {this.state.selectedRow.net_amount})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label for="inputReceipt">Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        name="paid_amount"
                        value={this.state.payment.paid_amount}
                        onChange={this.handlePaymentChange}
                      />
                      <span className="text-danger">
                        Due amount : {this.state.due_amount}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label for="inputReceipt">Payment Mode</label>
                      <select
                        className="form-control"
                        name="mode"
                        value={this.state.payment.mode}
                        onChange={this.handlePaymentChange}
                      >
                        <option value="">--Select--</option>
                        <option value="Cash">Cash</option>
                        <option value="Cheque">Cheque</option>
                        <option value="DD">DD</option>
                        <option value="UPI">UPI</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.updatePayment}
            >
              <i className="fa fa-save" />
              &nbsp; Save
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                this.setState({ payment_popup: false });
              }}
            >
              <i className="fa fa-times" />
              &nbsp; Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.edit_popup}
          onHide={() => {
            this.setState({ edit_popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="edit-scheme">Update Ad details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className="content">
              <div className="container-fluid">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">Bill Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bill number"
                          name="bill_number"
                          value={this.state.update.bill_number}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">Bill Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Bill date"
                          name="bill_date"
                          value={this.state.update.bill_date}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">RO Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="RO number"
                          name="ro_number"
                          value={this.state.update.ro_number}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">RO Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="RO date"
                          name="ro_date"
                          value={this.state.update.ro_date}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">Category</label>
                        <select
                          className="form-control"
                          name="category"
                          value={this.state.update.category}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">--Select--</option>
                          <option value="Private">Private</option>
                          <option value="PRD">PRD</option>
                          <option value="Lottery">Lottery</option>
                          <option value="DAVP">
                            {user.role === "A" ? "Magazine" : "DAVP"}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">Edition</label>
                        <select
                          className="form-control"
                          name="edition"
                          value={this.state.update.edition}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">--Select--</option>
                          <option value="0">All Kerala</option>
                          {this.state.branches.length > 0 &&
                            this.state.branches.map((item, i) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">District</label>
                        <select
                          className="form-control"
                          name="district"
                          value={this.state.update.district}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">--Select--</option>
                          {this.state.districts.length > 0 &&
                            this.state.districts.map((item, i) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">Agency</label>
                        <select
                          className="form-control"
                          name="ad_agency"
                          value={this.state.update.ad_agency}
                          onChange={this.handleUpdateChange}
                        >
                          <option value="">--Select--</option>
                          {this.state.agencies.length > 0 &&
                            this.state.agencies.map((item, i) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">Agency GST</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="GST"
                          name="agency_gst"
                          value={this.state.update.agency_gst}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">Client</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Client"
                          name="client"
                          value={this.state.update.client}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">Client GST</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Client GST"
                          name="client_gst_no"
                          value={this.state.update.client_gst_no}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">Insertion Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Insertion date"
                          name="insertion_date"
                          value={this.state.update.insertion_date}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label for="inputDate">Insertion page</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insertion page"
                          name="insertion_page"
                          value={this.state.update.insertion_page}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">Advertisement type</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Advertisement type"
                          name="ad_type"
                          value={this.state.update.ad_type}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="inputDate">Ad height(in CM)</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Ad height"
                              name="ad_height"
                              value={this.state.update.ad_height}
                              onChange={this.handleUpdateChange}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="inputDate">Ad width(in CM)</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Ad width"
                              name="ad_width"
                              value={this.state.update.ad_width}
                              onChange={this.handleUpdateChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">Rate</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Rate"
                          name="rate"
                          value={this.state.update.rate}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">Bill amount</label>
                        <input
                          type="readonly"
                          className="form-control"
                          placeholder="Bill Amount"
                          name="bill_amount"
                          value={this.state.update.bill_amount}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">Discount</label>
                        <input
                          type="readonly"
                          className="form-control"
                          placeholder="Discount"
                          name="discount"
                          value={this.state.update.discount}
                          onChange={this.handleUpdateChange}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">Taxable amount</label>
                        <input
                          type="readonly"
                          className="form-control"
                          placeholder="Taxable Amount"
                          name="taxable_amount"
                          value={this.state.update.taxable_amount}
                        />
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          name="is_sgst"
                          checked={this.state.update.is_sgst}
                          onChange={(event) => {
                            let { update } = this.state;
                            update.is_sgst = !this.state.update.is_sgst;
                            this.setState({
                              update: update,
                            });
                            this.setNetAmount();
                          }}
                          disabled={this.state.update.is_igst}
                        />
                        &nbsp;
                        <label for="inputDate">
                          Is SGST ({this.state.gst.sgst}%) applicable.?
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          name="is_cgst"
                          checked={this.state.update.is_cgst}
                          onChange={(event) => {
                            let { update } = this.state;
                            update.is_cgst = !this.state.update.is_cgst;
                            this.setState({
                              update: update,
                            });
                            this.setNetAmount();
                          }}
                          disabled={this.state.update.is_igst}
                        />
                        &nbsp;
                        <label for="inputDate">
                          Is CGST ({this.state.gst.cgst}%) applicable.?
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          name="is_igst"
                          checked={this.state.update.is_igst}
                          onChange={(event) => {
                            let { update } = this.state;
                            update.is_igst = !this.state.update.is_igst;
                            this.setState({
                              update: update,
                            });
                            this.setNetAmount();
                          }}
                          disabled={
                            this.state.update.is_sgst ||
                            this.state.update.is_cgst
                          }
                        />
                        &nbsp;
                        <label for="inputDate">
                          Is IGST ({this.state.gst.igst}%) applicable.?
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">
                          Net Amount : Rs. {this.state.net_amount}/-
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label for="inputDate">Published Edition(s)</label>
                        <select
                          className="form-control"
                          name="published_editions"
                          value={this.state.update.published_editions}
                          onChange={this.handleMultipleSelectChange}
                          multiple={true}
                        >
                          <option value="0">All Kerala</option>
                          {this.state.branches.length > 0 &&
                            this.state.branches.map((item, i) => {
                              return (
                                <option value={item.id}>{item.name}</option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.updateAd}
            >
              <i className="fa fa-save" />
              &nbsp; Save
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                this.setState({ edit_popup: false });
              }}
            >
              <i className="fa fa-times" />
              &nbsp; Close
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={this.state.password_popup}
          onHide={() => {
            this.setState({ password_popup: false });
          }}
          animation={true}
          aria-labelledby="example-modal-sizes-title-lg"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title id="edit-scheme">
              Authenticate with admin password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={this.state.pwd.password}
                        onChange={(event) => {
                          let pwd = this.state;
                          pwd[event.target.name] = event.target.value;
                          this.setState({ pwd });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.passwordCheck}
            >
              <i className="fa fa-save" />
              &nbsp; Authenticate
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                this.setState({ password_popup: false });
              }}
            >
              <i className="fa fa-times" />
              &nbsp; Close
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
